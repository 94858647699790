//TODO: Remember to delete unused fonts
$font-display: "Markazi Text", "Lora", "Georgia", serif; //400,500,600,700
// $font-body: "Lato", sans-serif; //100,300,400,700,900
$font-body: "Lato", sans-serif; //100,300,400,700,900

$golden-ratio: 1.61803399;

$break-SM: 24em;
$break-MD: 36em;
$break-LG: 48em;
$break-XL: 60em;

$break-mobile: 425px;
$break-tablet: 768px;

$red: #cf3232;
$blue: #0088ff;

$g90: #1a1a1a;
$g75: #404040;
$g55: #737373;
$g05: #e5e5e5;

* {
  cursor: default;
}

body {
  margin: 0;
  color: $g90;
}

a {
  cursor: pointer;
  color: $g90;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: $red;
    user-select: none;
  }
}

h1,
h2,
h3 {
  font-family: $font-display;
  font-weight: 700;
  line-height: 1.3;
}
h4 {
  font-family: $font-body;
  font-weight: 700;
  line-height: 1.3;
  display:block;
}

p,
div,
span,
ul,
ol,
li {
  font-family: $font-body;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
}

li { 
  margin-bottom: 16px
}

@media (min-width: $break-mobile) {
  h1 {
    font-size: 72px;
    line-height: 1;
    margin: 0;
  }
  h2 {
    font-size: 56px;
    line-height: 1;
    margin: 0;
  }
  h3 {
    font-size: 48px;
    line-height: 1;
    margin: 0;
  }
  h4 {
    font-size: 24px;
    line-height: 1;
    margin: 0;
  }

  p,
  span
  {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .cta-link {
    font-family: $font-body;
    font-weight: 800;
    font-size: 18px;
  }
}

@media (max-width: $break-mobile) {
  h1 {
    font-size: 48px;
    line-height: 1;
    margin: 0;
  }
  h2 {
    font-size: 40px;
    line-height: 1;
    margin: 0;
  }
  h3 {
    font-size: 36px;
    line-height: 1;
    margin: 0;
  }
  h4 {
    font-size: 28px;
    line-height: 1;
    margin: 0;
  }

  p,
  span
  {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .cta-link {
    font-family: $font-display;
    font-weight: 800;
    font-size: 24px;
    text-decoration: underline;
  }
}
